import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Lightbox from "../components/Lightbox"

const RealisatiesPage = ({data}) => (
    <Layout>
      <SEO title="Contacteer ons" />

      <h1>Contact opnemen met M&S bouwwerken</h1>

      <p>
        <strong>Andry Sowinski</strong><br />
        <a href={"tel:0032484802047"}>+32 484 80 20 47</a><br />
        <br />
        <strong>Gesuino Muroni</strong><br />
        <a href={"tel:0032484140037"}>+32 484 14 00 37</a><br />

      </p>

      <p>of stuur een email naar <a href={"mailto:info@msbouwwerken.be"}>info@msbouwwerken.be</a></p>

    </Layout>
  )

export default RealisatiesPage
